import React from "react"
import EmergencyMedicalCareBanner from "../components/EmergencyMedicalCareBanner"
import withLayout from "../layout/withLayout"
import SuperspecialityDoctorsTable from "../components/SuperspecialityDoctorsTable"
import FacilitiesGrid from "../components/FacilitiesGrid"
import DepartmentsAll from "../components/DepartmentsAll"
import DoctorsTable from "../components/DoctorsTable"
import Seo from "../components/Seo"
const FacilitiesPage = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title="Facilities and Services" description="Superspeciality Facilities and Services offered at Nemcare Tezpur" siteMetadata={siteData} />
      <div className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Departments and Facilities</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Facilities</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DepartmentsAll />

      <FacilitiesGrid />

      <SuperspecialityDoctorsTable shadow={false} />

      <EmergencyMedicalCareBanner siteData={siteData} />

      <section className="contact-area" style={{ marginTop: -50 }}>
        <div className="container">
          <div className="contact-inner" style={{ boxShadow: "none" }}>
            <div className="row">
              <div className={"col-sm-12"}>
                <div
                  className={"services-details-desc"}
                  style={{ boxShadow: "none" }}
                >
                  <span className="sub-title">Doctors</span>
                  <h3>List of Consultants and Speciality</h3>
                  <DoctorsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
})

export default FacilitiesPage
